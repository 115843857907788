import React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"

export default () => {
  return (
  	<Layout>
  		<img style={{ 'width': '200px' }} src="/images/404error.png" />
	    <h1>404: Diese Seite existiert leider nicht mehr!</h1>
	    <p>Durch den Relaunch dieser Website habe sich einige URLs geändert.<br/>
	    Bitte gehen Sie über die <Link to="/">Startseite</Link> und aktualisieren Sie ggf. Ihren Link auf eine bestimmte <i>Open Educational Resource</i>.</p>	    
	  </Layout>
	)
}
